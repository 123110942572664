import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

import { Icon } from '@types';

const titleQuery = graphql`
  {
    site: allSite {
      edges {
        node {
          siteMetadata {
            title
          }
        }
      }
    }
  }
`;

const Logo: Icon = ({ fill = "white" }) => {
  const results = useStaticQuery(titleQuery);
  const title = results.site.edges[0].node.siteMetadata.title;

  return (
    <LogoContainer>
      <LogoText>
        {title}
      </LogoText>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;

const LogoText = styled.h1`
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  margin: 0;
  font-weight: lighter;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.primary};
  border: 3px double ${(p) => p.theme.colors.primary};
  padding: 8px 16px;
  font-family: serif;
  text-rendering: optimizeLegibility;
  ${mediaqueries.tablet`
      font-size: 14px;
      padding: 4px 8px;
    `};
`;
